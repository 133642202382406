<template>
    <div class="panel--main">
        <AdminNav />

        <div class="lead-section panel-x">
            <h2 class="panel-x__title center">Welcome, admin</h2> 

           <div class="admin-stats">
               <div class="box mb-5">
                    <div class="box__header">
                        <h3 class="box__header-title">stats</h3>
                    </div>
                    <div class="box__body flex">
                        <div class="stats stats--main">
                        <div class="stats__amount">{{digits(partners.length)}}</div>
                        <div class="stats__caption">Partners</div>
                    
                        </div>

                        <div class="stats">
                        <div class="stats__amount">{{digits(clients.length)}}</div>
                        <div class="stats__caption">customers</div>
                    
                        </div>

                        <div class="stats">
                        <div class="stats__amount">{{digits(leads.length)}}</div>
                        <div class="stats__caption">Total leads</div>
                      
                        </div>

                        <div class="stats">
                        <div class="stats__amount">{{digits(alerts.length)}}</div>
                        <div class="stats__caption">Notifications</div>
                      
                        </div>
                    </div>
                    </div>
           </div>

            <div class="lead-section__filters mt-12">
                <b-input @input="searchLead" type="search" rounded size="is-medium" icon="magnify"  placeholder="Search for..."></b-input>
            </div>
            <br><br>
             <div class="lead-section__table">
               <b-tabs v-model="activeTab" type="is-toggle" expanded position="is-centered" class="block">
                    <b-tab-item label="Leads">
                       <client-list :leadi="leads"></client-list>
                    </b-tab-item>

                    <b-tab-item label="Customers">
                        <client-list :leadi="clients"></client-list>
                    </b-tab-item>
                </b-tabs>
            </div>

            <empty v-show="leads.length == 0"><h1>No leads</h1></empty>

        </div>
    </div>
</template>


<script>

import AdminNav from '@/components/AdminNav.vue'
import Empty from '@/components/Empty.vue'

import { db, customSearch, adminUID } from '../../utils'
import { users } from '@/stores/index'
import ClientList from './ClientList.vue'
import firebase from 'firebase'
 


export default {
    name: 'Leads',
    components: {
        AdminNav, ClientList, Empty
    },
    data: () => ({
        user: {}, form: {}, store: users(), leads: [], isOpen: 0, alerts: [],
        clients: [], activeTab: 0, leadCopy: [], clientCopy: [], partners: []
    }),
    created(){
      firebase.auth().onAuthStateChanged((user) => {
        if (!user || user.uid !== adminUID) return this.$router.push('/admin')
            this.store.logged = user
            this.init()
        })
    },
    mounted(){
       this.$root.$on('Leads', (items) => {
          this.alerts = items
        })
    },
    methods:{
        init(){
            db.collection("leads").onSnapshot((doc) => {
                this.leads = []
                 doc.forEach((items) => {
                   let item = {...items.data(), ... {oid: items.id}}
                   this.leads.push(item)
                })
                this.clients = this.leads.filter(i => i.status == 'client')
                this.leadCopy = this.leads
                this.clientCopy = this.clients
            });

            db.collection("partners").onSnapshot((doc) => {
                this.partners = []
                 doc.forEach((items) => {
                    let item = {...items.data(), ... {oid: items.id}}
                   this.partners.push(item)
                })
                this.store.partners = this.partners
            });
        },
        searchLead(e){
                let leadsO = this.leadCopy, clientsO = this.clientCopy;
                this.leads = customSearch(leadsO, e)
                this.clients = customSearch(clientsO, e)

                if(!e || e == '') {
                    this.leads = this.leadCopy
                    this.clients = this.clientCopy
            }
        },
        digits(n){
            return n > 9 ? n : '0'+n
        }
    },
 

}
</script>


<style lang="scss" scoped>
@import '../../assets/scss/style.scss';

::v-deep .tabs li a{
    border-radius: 0px !important;
}

::v-deep .tabs.is-toggle li.is-active a{
    background: $primary !important;
    border-color: $primary !important;
}




.box {
	background: linear-gradient(#526494, #604484);
	margin: 15px auto 0;
	border-radius: 5px;
	//width: 300px;
}

.box__header {
	padding: 15px 25px;
	position: relative;
}

.box__header-title {
	color: #fff;
	font-size: 18px;
}

.box__body {
	padding: 0 25px;
}

/* STATS */

.stats {
	color: #fff;
	position: relative;
	padding-bottom: 25px;
}

.stats__amount {
	font-size: 30px;
	font-weight: bold;
	line-height: 1.2;
}

.stats__caption {
	font-size: 12px;
    text-transform: uppercase;
    margin-top: 5px;

}



.stats__value {
	font-size: 18px;
}

.stats__period {
	font-size: 14px;
}

.stats__value--positive {
	color: #AEDC6F;
}

.stats__value--negative {
	color: #FB5055;
}

.stats--main .stats__amount {
	font-size: 35px;
}
</style>