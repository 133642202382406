<template>
    <div>
       <b-collapse class="ui-collapse"
            animation="slide" v-for="(item, index) of leadi" :key="index" :open="isOpen == index" @open="isOpen = index" 
            :aria-id="'contentIdForA11y5-' + index" :data-sefn="item.fname" :data-seln="item.lname">
            <template #trigger="props">
                <div role="button" :aria-controls="'contentIdForA11y5-' + index" :aria-expanded="props.open">
                    <div class="lead-list-main">
                        <div class="lead-date center">
                            <h1 class="uppercase">{{datefm(item.date)}}</h1>
                        </div>
                        <div class="lead-fullname">
                            <h4>{{item.fname}}</h4> <h5>{{item.lname}}</h5>
                        </div>
                        <div class="lead-phone-email">
                            <h4>{{item.phone}}</h4>
                            <h5>{{item.email}}</h5>
                        </div>
                        <div class="lead-status center">
                            <div class="icon-lead" :class="statusColor(item.status)">
                                <i class='bx' :class="leadIcon(item.status)"></i>
                            </div>
                            <h5 class="uppercase">{{item.status}}</h5>
                        </div>
                        <div class="leaf-ref__amount">
                               <b-tooltip type="is-light" label="Earning">
                                    <h3>£{{item.ref_amount}}</h3>
                                </b-tooltip>
                        </div>
                        <a class="card-header-icon">
                        <b-icon size="is-large" :icon="props.open ? 'chevron-down' : 'chevron-up'"></b-icon>
                    </a>
                    </div>
                </div>
            </template>
            <div class="lead-bottom-content">
                <div class="flex mt-5">
                    <div class="w-1-2 flex flex-left">
                        <b-field>
                            <b-select size="is-small" v-model="item.status" placeholder="Update status">
                                <option 
                                    v-for="(option, index) in status" :value="option" :key="index">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>
                        <div class="control ml-3">
                            <b-tooltip label="How much will partner earn for this lead?" type="is-light">
                               <b-input size="is-small" icon="currency-gbp" class="w-1-1" v-model="item.ref_amount" placeholder="Referal amount"></b-input>
                            </b-tooltip>
                            
                        </div>
                    </div>
                    <div class="w-1-2 right">
                        <b-button type="is-primary is-light" size="is-small" @click="editLead(item)">Save changes</b-button>
                    </div>
                </div>
                <p class="text-small">Partner referal ID: {{item.partnerId}}</p>
            </div>
        </b-collapse>
    </div>
</template>



<script>
import moment from 'moment'
import { db } from '../../utils'

export default {
    name: 'ClientList',
    props: ['leadi'],
   data(){
       return {
           leads: this.leadi,
           isOpen: 0, status: [
               'working lead', 'dead lead', 'client', 'lead added'
           ]
       }
   },
   methods: {
        statusColor(status){
            return status == 'lead added' ? 'is-info' : status == 'working lead' ? 'is-link' 
            : status == 'dead lead' ? 'is-danger' : 'is-success'
        },
        leadIcon(status){
            return status == 'lead added' ? 'bxs-circle-half' : status == 'working lead' ? 'bxs-circle-three-quarter' 
            : status == 'dead lead' ? 'bxs-circle' : 'bxs-party'
        },
        editLead(item){
            db.collection('leads').doc(item.oid).update({
                status: item.status,
                ref_amount: item.ref_amount
            }).catch((err) => console.log(err))
             this.$buefy.toast.open({
                message: 'Lead changes saved!',
                type: 'is-success'
             })
        },
          datefm(d){
            return moment(d).format('D MMM')
        },
   }
}
</script>




<style lang="scss" scoped>
@import '../../assets/scss/style.scss';
@import '../../assets/scss/lead.scss';

.lead-section__table{
   padding-bottom: 20px;
   border-radius: 20px 20px  0 0;
}



</style>